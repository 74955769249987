<template>
  <div>
    <h1>从后端获取的数据</h1>
    <p>{{ testData }}</p>
    <button @click="fetchTestData">加载数据</button>
  </div>
</template>

<script>
import { getTest } from '../request/api/home'; // 确保路径正确
import { getWeather } from '../request/api/home';
export default {
  data() {
    return {
      testData: '等待加载数据...'
    };
  },
  methods: {
    fetchTestData() {
      getWeather()
          .then(response => {
            this.testData = response.data;
            console.log('数据加载成功:', response);
          })
          .catch(error => {
            this.testData = '数据加载失败';
            console.error('错误:', error);
          });
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 24px;
}
p {
  font-size: 18px;
}
button {
  font-size: 16px;
  padding: 8px 16px;
}
</style>
